// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bh_k5";
export var caseStudyBackgroundContainer = "bh_kY";
export var caseStudyBackgroundContainer__ship = "bh_kZ";
export var caseStudyBackground__bgColor = "bh_kW";
export var caseStudyBackground__lineColor = "bh_kX";
export var caseStudyHead__imageWrapper = "bh_kV";
export var caseStudyProjectsSection = "bh_k6";
export var caseStudyQuote__bgLight = "bh_k3";
export var caseStudyQuote__bgRing = "bh_k2";
export var caseStudySolution__ring = "bh_k0";
export var caseStudySolution__ringTwo = "bh_k1";
export var caseStudyTech = "bh_k4";
export var caseStudy__bgDark = "bh_kT";
export var caseStudy__bgLight = "bh_kS";